import { useEffect } from 'react'

export function useScroll(callback: unknown, ...deps: any) {
  useEffect(() => {
    if (!window.lenis) return
    window.lenis?.on('scroll', callback, { passive: true })
    window.lenis?.emit()

    return () => {
      window.lenis?.off('scroll', callback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...deps])
}
