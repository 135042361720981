import React, { ReactNode, useCallback } from 'react'
import { HTMLProps } from 'react'
import cn from 'classnames'
import s from './MenuItem.module.scss'
import { useRouter } from 'next/router'
import Link from 'next/link'

type subNavName = 'Home' | 'Products' | 'FSI Solution' | 'AboutUs' | 'Resources' | 'News' | 'lang' | ''
export type IMenuItemProps = HTMLProps<HTMLDivElement> & {
  isCheck?: boolean //是否选中状态
  label: ReactNode
  icon?: ReactNode
  href?: string
  desc?: string
  name?: subNavName
  onClickMenu?: (href: string, name: subNavName) => void
}
// 只包含中文和英文的路径
const PATH_ONLY_ZH_AND_EN = ['/blog', '/news', '/events', '/marketupdates']
const MenuItem: React.FC<IMenuItemProps> = ({
  isCheck = false,
  icon,
  label,
  href = '',
  desc,
  name = '',
  onClickMenu,
}) => {
  const router = useRouter()

  const menuClick = useCallback(() => {
    onClickMenu?.(href, name)
  }, [href, name, onClickMenu])

  const isSpecialPath = PATH_ONLY_ZH_AND_EN.includes(href) &&
    !(router.locale === 'en' || (router.locale === 'zh' && href !== '/events'))

  const content = (
    <>
      <div className={s.label}>
        {icon}
        <span className={cn(s.label_a, { [s.label_a_hover]: href, [s.labelCheck]: isCheck })}>{label}</span>
      </div>
      {desc && <div className={s.desc}>{desc}</div>}
    </>
  )


  return (
    <div className={cn(s.menu_box, { [s.menu_box_hover]: href, [s.menu_box_Ar]: router.locale === 'ar' })}>
      {isSpecialPath ? (
        <div className={s.label_warpper} onClick={menuClick}>
          {content}
        </div>
      ) : (
        <Link href={href}>
          <a className={s.label_warpper} onClick={menuClick} target={name ? '_self' : '_blank'}>
            {content}
          </a>
        </Link>
      )}
    </div>
  )
}

export default React.memo(MenuItem)
