import { AppContext, AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import Layout from '@/components/Layout'
import 'aos/dist/aos.css'
import '../styles/global.scss'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Lenis from '@studio-freight/lenis'
import { useFrame } from '@studio-freight/hamo'
import dynamic from 'next/dynamic'


const ToastContainer = dynamic(() => import('@/components/Toast').then((mod: any) => mod.ToastContainer), { ssr: false })
const arrLang = ['zh', 'en', 'ja', 'kr', 'fr', 'pt', 'ar', 'es']

const MOBILE_BREAKPOINT = 800
const MOBILE_KEYWORDS = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
const SPECIAL_ROUTES = ['/blog', '/news', '/marketupdates', '/events']




function MyApp({ Component, pageProps }: AppProps) {
  const [isH5, setIsH5] = useState<boolean>(pageProps.isMobile)
  const layoutRef = useRef<HTMLDivElement | null>(null)
  useFrame((time: any) => {
    window?.lenis?.raf(time)
  })
  useEffect(() => {
    if (window?.lenis) {
      window?.lenis?.start()
    }


  }, [])
  useEffect(() => {
    let lenis: any = null;
    if (typeof window !== 'undefined') {
      lenis = new Lenis({
        orientation: 'vertical', // vertical, horizontal
        gestureOrientation: 'vertical', // vertical, horizontal, both
        smoothWheel: true,
        wheelMultiplier: 1,
        touchMultiplier: 2,
        infinite: false
      })
      window.lenis = lenis
      ScrollTrigger.defaults({ markers: process.env.NODE_ENV === 'development' })
      lenis.on('scroll', ScrollTrigger.update)
      // Set up RAF
      const raf = (time: number) => {
        lenis.raf(time)
        requestAnimationFrame(raf)
      }
      requestAnimationFrame(raf)

    }
    window.history.scrollRestoration = 'manual'
    // Cleanup
    return () => {
      if (lenis) {
        lenis.destroy()
        window.lenis = null
      }
    }

  }, [])



  const router = useRouter()
  const { locale = 'en' } = router
  const handleResize = useCallback(() => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    import('aos').then((AOS) => {
      window.Aos = AOS
      AOS.init({
        offset: width <= MOBILE_BREAKPOINT ? 20 : 40,
        duration: width <= MOBILE_BREAKPOINT ? 400 : 1000,
        delay: 100,
        once: false
      })
    }).catch(console.error) // 简化错误处理
    setIsH5(width <= MOBILE_BREAKPOINT)
  }, []) // 移除不必要的依赖 isH5
  useEffect(() => {
    // 来源网址 提交表单使用
    const from = localStorage.getItem('from')
    if (!from) {
      localStorage.setItem('from', String(`${location.href}`))
    }
    handleResize()
    window.addEventListener('resize', handleResize, { passive: true })
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])





  useEffect(() => {
    //主要处理到新闻等列表语言（只有中英文的情况）
    let isFill = false
    const handleRouteStart = (e: string) => {
      //  router.pathname 当前页面
      const isSpecialRoute = (path: string) =>
        SPECIAL_ROUTES.some(route => path.includes(route))
      const beforeEspeciallyUrl = isSpecialRoute(router.pathname)
      //e 将要跳转的页面
      const afterEspeciallyUrl = isSpecialRoute(e)
      //从其他页面到特殊页面 要存下来当前的语言
      if (!beforeEspeciallyUrl && afterEspeciallyUrl) {
        localStorage.setItem('currentLang', locale || 'en')
      }
      //从特殊页面到其他页面
      if (beforeEspeciallyUrl && !afterEspeciallyUrl) {
        const beforeLocal = localStorage.getItem('currentLang') || ''
        let afterPathLang = beforeLocal
        if (locale == 'zh' && beforeLocal != locale) {
          afterPathLang = 'zh'
        } else if (beforeLocal != locale) {
          afterPathLang = beforeLocal
        }
        localStorage.setItem('currentLang', afterPathLang || 'en')
        const path = arrLang.indexOf(e.split('/')[1]) != -1 ? e.slice(3) : e
        if (isFill) {
          isFill = false
          router.push({
            pathname: `/${afterPathLang}/${path}`
          })
        }
      }
      NProgress.start()
    }
    const handleRouteChange = (e: { preventDefault: () => void }) => {
      e.preventDefault?.()
      window.lenis?.start()
      NProgress.done(false)
      isFill = true
      setTimeout(() => {
        window?.Aos?.refresh()
      }, 500)
    }
    router.events.on('routeChangeStart', handleRouteStart)
    router.events.on('routeChangeError', () => {
      NProgress.done(false)
    })
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('routeChangeStart', handleRouteStart)
      router.events.off('routeChangeError', () => {
        NProgress.done(false)
      })
    }
  }, [locale, router])
  const getFooterHeight = useCallback(() => {
    return layoutRef.current?.clientHeight
  }, [])

  return (
    < >
      <Layout isH5={isH5} type={router.pathname == '/' ? 1 : 0} ref={layoutRef} hiddenHeader={pageProps?.hiddenHeader}>
        <Component {...pageProps} isH5={isH5} getFooterHeight={getFooterHeight} />
      </Layout>
      <ToastContainer {...pageProps} />
    </>
  )
}


MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  const userAgent = ctx.req?.headers['user-agent']
  const isMobile = userAgent ?
    MOBILE_KEYWORDS.some(keyword => userAgent.includes(keyword)) :
    false

  const pageProps = Component.getInitialProps ?
    await Component.getInitialProps(ctx) :
    {}

  return { pageProps: { ...pageProps, isMobile } }
}



export default appWithTranslation(MyApp)


