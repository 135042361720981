import React, { useEffect, forwardRef } from 'react'
import Head from 'next/head'
import styles from './index.module.scss'
import Header from '../Header'
import dynamic from 'next/dynamic'

const Footer = dynamic(() => import('@/components/Footer'))
declare global {
  interface Window {
    lenis: any
  }
}

interface LayoutProps {
  isH5?: boolean;
  children: React.ReactNode;
  type?: number;
  hiddenHeader?: boolean;
}


const Layout = forwardRef<HTMLElement, LayoutProps>(({
  isH5,
  children,
  type,
  hiddenHeader = false
}, ref) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <main id="layout">
        {!hiddenHeader && <Header isH5={isH5} />}
        {children}
        {type == 1 ? (
          <div className={styles.footer}>
            <Footer type={1} grandRef={ref} />
          </div>
        ) : (
          <Footer grandRef={ref} />
        )}
      </main>
    </>
  )
})

Layout.displayName = 'Layout'

export default Layout
